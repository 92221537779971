import React from 'react'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';


export default function Aboutus(props) {
  return (
    <div style={{
      background: ' rgb(173,215,255)',
      background: 'linear-gradient(0deg, rgba(173,215,255,1) 0%, rgba(246,246,246,1) 100%)'
    }}>
      <Grid container>
        <Grid item xs={12} md={12} xl={12} lg={12} sx={{
          textAlign: 'center', padding: '2%', fontWeight: 'bold', color: '#00417C',
          backgroundColor: { xl: "", md: "", xs: "" }, fontSize: {  xl: "30px", md: "25px", xs: "17px"}
        }}>
          {props.t('About')}
        </Grid>
      </Grid>
      <Grid container style={{}}>
        <Grid item xs={0.5} md={1.5} xl={1.5} lg={1.5} style={{}} />
        <Grid item xs={11} md={9} xl={9} lg={9}>
          <Box sx={{
            backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "18px", md: "15px", xs: "13px", }, lineHeight: '195%', textAlign: 'justify'
          }}>
            {props.t('BESTTECH')}<br />
            {props.t('ICT')}<br />
            {props.t('Sale')}<br />
            {props.t('Sof')}<br />
            {props.t('IC')}<br />
            {props.i18n.language === "la" &&
              <Box>
                ໄດ້ປະກອບສ່ວນພັນທະອາກອນຕາມກໍານົດເວລາແລະລະບຽບກົດໝາຍຕັ້ງແຕ່ເລີ່ມຕົ້ນ (2017) ຈົນເຖິງປັດຈຸບັນ.
              </Box>
            }
          </Box>
        </Grid>
        <Grid item xs={0.5} md={1.5} xl={1.5} lg={1.5} style={{}} />
      </Grid>
      <br />
      <Grid container>
        <Grid item xs={12} md={12} xl={12} lg={12} sx={{
          textAlign: 'center', fontWeight: 'bold', color: '#00417C',
          backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "25px", md: "18px", xs: "13px" }
        }}>
          {props.t('Our')}
        </Grid>
      </Grid>
      <br />
      <Grid container style={{}}>
        <Grid item xs={0.5} md={1.5} xl={1.5} lg={1.5} style={{}} />
        <Grid item xs={11} md={9} xl={9} lg={9}>
          <Box sx={{
            backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "18px", md: "15px", xs: "13px", }, lineHeight: '195%', textAlign: 'justify'
          }}>
            {props.t('The')}<br />
            {props.t('BEST')}<br />
            {props.t('Most')}<br />
            {props.t('Inn')}<br />
            {props.t('BE')}<br />
          </Box>
        </Grid>
        <Grid item xs={0.5} md={1.5} xl={1.5} lg={1.5} style={{}} />
      </Grid>
      <div style={{ padding: '2%' }}></div>
    </div>
  )
}

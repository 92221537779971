import React from 'react'
import Grid from '@mui/material/Grid';
import Wm from '../../modules/Wm.png'
import IT from '../../modules/IT.png'
import Virtual from '../../modules/Virtual.png'
import Devops from '../../modules/Devops.png'



export default function OurServiec(props) {
  return (
    <div style={{
      background: 'rgb(208,255,252)',
      background: 'linear-gradient(90deg, rgba(208,255,252,1) 0%, rgba(255,255,255,1) 100%)'
    }}>
      <Grid container>
        <Grid item xs={12} md={12} xl={12} lg={12} sx={{
          textAlign: 'center', padding: '2%', fontWeight: 'bold',color:'#00417C',
          backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "30px", md: "25px", xs: "15px" }
        }}>
          {props.t('ser')}
        </Grid>
      </Grid>
      <Grid container >
        <Grid item xs={12} md={1} xl={1} lg={1} style={{}} />
        <Grid item xs={12} md={5} xl={5} lg={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src={Wm} style={{ width: '60%', }} />
        </Grid>
        <Grid item xs={12} md={5.5} xl={5.5} lg={5.5}
          sx={{ backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "18px", md: "15px", xs: "13px" } }}>
          <div style={{ padding: '6%' }}>
            {props.t('Website')}<br />
            {props.t('Poi')}<br />
            {props.t('Res')}<br />
            {props.t('Hot')}<br />
            {props.t('Ent')}<br />
            {props.t('Acc')}<br />
            {props.t('In')}<br />
            {props.t('Sa')} <br />
            {props.t('E-commerce')}<br />
            {props.t('IoT')}<br />
            {props.t('Etc')}<br />
          </div>
        </Grid>
        <Grid item xs={12} md={0.5} xl={0.5} lg={0.5} style={{}} />
      </Grid>

      <Grid container style={{
        background: 'rgb(255,255,255)',
        background: ' linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(208,255,252,1) 100%)'
      }}>
        <Grid item xs={12} md={0.5} xl={0.5} lg={0.5} style={{}} />
        <Grid item xs={12} md={5.5} xl={5.5} lg={5.5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src={IT} style={{ width: '50%', }} />
        </Grid>
        <Grid item xs={12} md={5.5} xl={5.5} lg={5.5}
          sx={{ backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "18px", md: "15px", xs: "13px" } }}>
          <div style={{ padding: '6%' }}>
            {props.t('IT')}<br />
            {props.t('De')}<br />
            {props.t('Des')}<br />
            {props.t('Desig')}<br />
            {props.t('Design')}<br />
            {props.t('Active')}<br />
            {props.t('System')}<br />
            {props.t('Backup')}<br />
            {props.t('Consultant')}<br />
            {props.t('Sy')}<br />
            {props.t('Et')}
          </div>
        </Grid>
        <Grid item xs={12} md={0.5} xl={0.5} lg={0.5} style={{}} />
      </Grid>

      <Grid container style={{
        background: 'rgb(208,255,252)',
        background: 'linear-gradient(90deg, rgba(208,255,252,1) 0%, rgba(255,255,255,1) 100%)'
      }}>
        <Grid item xs={12} md={0.5} xl={0.5} lg={0.5} style={{}} />
        <Grid item xs={12} md={5.5} xl={5.5} lg={5.5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src={Virtual} style={{ width: '60%', }} />
        </Grid>
        <Grid item xs={12} md={5.5} xl={5.5} lg={5.5}
          sx={{ backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "18px", md: "15px", xs: "13px" } }}>
          <div style={{ padding: '6%' }}>
            {props.t('Vi')}<br />
            {props.t('Designn')}<br />
            {props.t('Con')}<br />
            {props.t('Configure')}<br />
            {props.t('Implement')}<br />
            {props.t('Imple')}<br />
            {props.t('Im')}<br />
            {props.t('Sal')}<br />
            {props.t('Etcc')}
          </div>
        </Grid>
        <Grid item xs={12} md={0.5} xl={0.5} lg={0.5} style={{}} />
      </Grid>

      <Grid container style={{
        background: 'rgb(255,255,255)',
        background: ' linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(208,255,252,1) 100%)'
      }}>
        <Grid item xs={12} md={0.5} xl={0.5} lg={0.5} style={{}} />
        <Grid item xs={12} md={5.5} xl={5.5} lg={5.5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src={Devops} style={{ width: '60%', padding: '2%' }} />
        </Grid>
        <Grid item xs={12} md={5.5} xl={5.5} lg={5.5}
          sx={{ backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "18px", md: "15px", xs: "13px" },textAlign: 'justify' }}>
          <div style={{ padding: '6%' }}>
            {props.t('Deve')}<br />
            {props.t('Fo')}<br />
            {props.t('Improve')}<br />
            {props.t('Maximize')}<br />
            {props.t('Minimize')}<br />
            {props.t('Etcc')}<br />
          </div>
        </Grid>
        <Grid item xs={12} md={0.5} xl={0.5} lg={0.5} style={{}} />
      </Grid>
    </div>
  )
}

import React from 'react';
import Grid from '@mui/material/Grid';
import PhoneIcon from '@mui/icons-material/LocalPhone';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TiemIcon from '@mui/icons-material/AvTimer';
import { makeStyles } from '@mui/styles';
import A2 from '../../modules/2.png'
import ShareIconList from '../ShareIconList'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import { Box } from '@mui/material';


const uesStyles = makeStyles((theme) => ({
    none: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
}))

export default function Appbar() {

    const classes = uesStyles();

    return (
        <div style={{
            background: 'rgb(246,246,246)',
            background: 'linear-gradient(0deg, rgba(246,246,246,1) 0%, rgba(192,220,226,1) 100%)'
        }}>
            {/* <Grid container>
                <Grid item xs={12} md={6} xl={6} lg={6} style={{}}></Grid>
                <Grid item xs={5} sm={6} md={2} xl={2} lg={2} style={{}}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <PhoneIcon sx={{ backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "22px", md: "15px", xs: "12px" } }} />
                        <Box sx={{ backgroundColor: { xl: "", md: "", xs: "" }, fontSize: {xl: "16px", md: "14px", xs: "12px" } }} >
                            +856 20 77800990
                        </Box>
                    </div>
                </Grid>
                <Grid item xs={7} sm={6} md={2} xl={2} lg={2} style={{}}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <LocationOnIcon sx={{ backgroundColor: { xl: "", md: "", xs: "" }, fontSize: {  xl: "22px", md: "15px", xs: "12px"  } }} />
                        <Box sx={{ backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "16px", md: "14px", xs: "12px" } }} >
                            Donekoy,Vientiane,Laos
                        </Box>
                    </div>
                </Grid>
                <Grid item xs={12} md={2} xl={2} lg={2} className={classes.none} style={{}}>
                    <div style={{ display: 'flex', justifyContent: 'center' }} >
                        <TiemIcon sx={{ backgroundColor: { xl: "", md: "", xs: "" }, fontSize: {  xl: "22px", md: "15px", xs: "12px"  } }} />
                        <Box sx={{ backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "16px", md: "14px", xs: "12px"  } }} >
                            Mon-Fri, 8:30-17;00
                        </Box>
                    </div>
                </Grid>
            </Grid> */}
            <Grid container>
                <Grid item xs={4} md={3} xl={3} lg={3} style={{}}>
                    <div style={{ textAlign: 'center' }}>
                        <img src={A2} alt='' style={{ width: '20vw', padding: '4%' }} />
                    </div>
                </Grid>
                <Grid item xs={8} md={7.1} xl={7.1} lg={7.1} style={{}}>
                    <div style={{ padding: '1.4%' }}></div>
                    <Box sx={{ backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "30px", md: "25px", xs: "10px" }, color: '#00417C', fontWeight: 'bold', }} >
                        ບໍລິສັດ ເບດເທັກ ຂາເຂົ້າ-ຂາອອກ ຈໍາກັດຜູ້ດຽວ
                    </Box>
                    <Box sx={{ backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "22px", md: "18px", xs: "7.5px" }, color: '#00417C', fontWeight: 'bold', }} >
                        BESTTECH IMPORT-EXPORT SOLE CO., LTD
                    </Box>
                </Grid>
                {/* <Grid item xs={12} md={1.9} xl={1.9} lg={1.9} className={classes.none} style={{}}>
                    <div style={{ padding: '7%' }}></div>
                    <Box sx={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', padding: '1%' }}>
                        <a href='https://www.facebook.com/besttech.la' style={{ display: 'flex', padding: '6%' }}>
                            <FacebookOutlinedIcon style={{ color: '#2534C0', fontSize: '150%' }} />
                        </a>
                        <ShareIconList style={{ padding: '' }} shareLink={"https://www.besttech.la/"} />
                    </Box>
                </Grid> */}
            </Grid>

        </div >
    );
}
import React, { useState } from 'react'
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';
import PhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import axios from 'axios'


export default function Contact(props) {

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setphone] = useState('')
  const [messenger, setMessenger] = useState('')

  const sendMail = () => {
    const options = {
      url: "https://rest.besttech.la/api/phone",
      method: "POST",
      headers: {
        "Accept": "application/json",
        "content-Type": "application/json;charset-UTF-8"
      },
      data: {
        name: name,
        email: email,
        phone: phone,
        messenger: messenger
      }

    }
    axios(options).then(res => {
      console.log(res.data)
    })
  }
  const Name = (e) => {
    setName(e.target.value)
  }
  const Email = (e) => {
    setEmail(e.target.value)
  }
  const Phone = (e) => {
    setphone(e.target.value)
  }
  const Messenger = (e) => {
    setMessenger(e.target.value)
  }


  return (
    <div style={{
      background: 'rgb(192,226,223)',
      background: 'linear-gradient(0deg, rgba(192,226,223,1) 0%, rgba(246,246,246,1) 100%)'
    }}>
      <Grid container>
        <Grid item xs={12} md={12} xl={12} lg={12} sx={{
          textAlign: 'center', padding: '1%', fontWeight: 'bold', color: '#00417C',
          backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "30px", md: "25px", xs: "17px" }
        }}>
          {props.t('Contact')}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12} xl={12} lg={12} sx={{
          textAlign: 'center', padding: '1%', fontWeight: 'bold', color: '#00417C',
          backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "18px", md: "17px", xs: "15px" }
        }}>
          {props.t('We')}
        </Grid>
      </Grid>
      <Grid container style={{ padding: '1%' }}>
        <Grid item xs={12} md={6} xl={6} lg={6} sx={{ textAlign: 'center', }}>
          <TextField
            style={{ width: '95%' }}
            helperText=" "
            id="demo-helper-text-aligned-no-helper"
            label={props.t('Name')}
            onChange={Name}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={6} lg={6} sx={{ textAlign: 'center' }}>
          <TextField
            style={{ width: '95%' }}
            helperText=" "
            id="demo-helper-text-aligned-no-helper"
            label={props.t('Company')}
            onChange={Email}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={6} lg={6} sx={{ textAlign: 'center' }}>
          <TextField
            style={{ width: '95%' }}
            helperText=" "
            id="demo-helper-text-aligned-no-helper"
            label={props.t('Phone')}
            onChange={Phone}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={6} lg={6} sx={{ textAlign: 'center' }}>
          <TextField
            style={{ width: '95%' }}
            id="outlined-multiline-static"
            label={props.t('Message')}
            onChange={Messenger}
            multiline
            rows={5}
          />
        </Grid>
      </Grid>
      <Grid container>
      <Grid item xs={1} md={5.5} xl={5.5} lg={5.5}>
        </Grid>
        <Grid item xs={10} md={1} xl={1} lg={1} sx={{
          color: '#ffffff',
          backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "20px", md: "15px", xs: "10px"}
        }}>
          <Box variant="contained" color="success"
            onClick={sendMail}
            style={{
              textTransform: 'none', backgroundColor: '#BBBBBB', height: '105%', display: 'flex', alignItems: 'end', justifyContent: 'center',
              boxShadow: '1px 1px 5px #010101', borderRadius: 2, cursor: 'pointer',color:'#000000'
            }}>
            {props.t('Submit')}
          </Box>
        </Grid>
        <Grid item xs={1} md={5.5} xl={5.5} lg={5.5}>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} md={12} xl={12} lg={12} sx={{
          textAlign: 'center', padding: '2.5%', fontWeight: 'bold', color: '#00417C',
          backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "20px", md: "17px", xs: "15px" }
        }}>
          {props.t('Head')}
        </Grid>
      </Grid>

      <Grid container style={{ padding: '2%' }}>
        <Grid item xs={12} md={1.5} xl={1.5} lg={1.5} style={{}} />
        <Grid item xs={12} md={4.5} xl={4.5} lg={4.5} style={{}}>
          <Box sx={{
            backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "20px", md: "15px", xs: "13px" },
            display: 'flex', padding: '1%'
          }}>
            <div style={{ display: 'flex' }}>
              <div style={{padding:'0% 2%'}}>
                <LocationOnIcon style={{ fontSize: '120%' }} />
              </div>
              <div style={{}}>
                {props.t('Ban')}
              </div>
            </div>
          </Box>
          <Box sx={{
            backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "20px", md: "15px", xs: "13px" },
            display: 'flex', padding: '1%'
          }}>
            <div style={{padding:'0% 2%'}}>
            <PhoneIcon style={{ fontSize: '118%' }} />
            </div>
            <div >
              +856 20 77800990
            </div>
          </Box>
          <Box sx={{
            backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "20px", md: "15px", xs: "13px" },
            display: 'flex', padding: '1%'
          }}>
            <div style={{ display: 'flex' }}>
              <div style={{padding:'0.6% 6%'}}>
                <EmailIcon style={{ fontSize: '118%' }} />
              </div>
              <div style={{}}>
                info@besttech.la
              </div>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} xl={4} lg={4}>
          <Box sx={{ backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "30px", md: "25px", xs: "17px" } }}>
            <div>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15834554.527361358!2d84.3914893713192!3d14.319677505658051!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31246732f0299845%3A0x354e4ad63d0c3741!2sBESTTECH%20SOLE%20CO%2C.LTD!5e0!3m2!1sen!2sla!4v1662717011324!5m2!1sen!2sla"
                alt='' style={{ width: "100%", height: "50vh", padding: '1%' }}
                allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} md={2} xl={2} lg={2} style={{}} />
      </Grid>
    </div >
  )
}

import React from 'react'
import Grid from '@mui/material/Grid';
import Pos from '../../modules/Pos.png'
import DO from '../../modules/DO.png'
import { Box, Button } from '@mui/material';
import Slider from 'infinite-react-carousel';
import Phone from '../../modules/Phone.png'
import Web from '../../modules/Web.png'
import Deops from '../../modules/Deops.png'
import FB from '../../modules/FB.png'
import { useNavigate } from "react-router-dom";



export default function Homepage(props) {
    const navigate = useNavigate();
    const Seemore = () => {
        navigate("/ourservices")
    }
    return (
        <div style={{
            background: 'rgb(192,226,223)',
            background: 'linear-gradient(0deg, rgba(192,226,223,1) 0%, rgba(246,246,246,1) 100%)'
        }}>
            <Grid container>
                <Grid item xs={12} md={12} xl={12} lg={12} sx={{
                    textAlign: 'center', padding: '2%', fontWeight: 'bold',color:'#00417C',
                    backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "30px", md: "25px", xs: "15px" }
                }}>
                    {props.t('service.1')}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={0.5} md={0.5} xl={0.5} lg={0.5} style={{}} />
                <Grid item xs={11.5} md={5.5} xl={5.5} lg={5.5} style={{}}>
                    <Box sx={{
                        textAlign: 'center', fontWeight: 'bold',color:'#00417C',
                        backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "18px", md: "15px", xs: "13px"  }
                    }}>
                        {props.t('Software')}
                    </Box>
                    <div style={{ padding: '1%' }}></div>
                    <Box sx={{
                        backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "18px", md: "15px", xs: "13px" }
                    }}>
                        <img src={Pos} style={{ width: '40%', float: 'left', padding: '0% 3%' }} />
                        <div style={{ padding: '2.5%' }}></div>
                        {props.t('Point')} <br />
                        {props.t('E')}<br />
                        {props.t('Management')}<br />
                        {props.t('Hotel')}<br />
                        {props.t('Inventory')}<br />
                        {props.t('etc')}
                    </Box>
                </Grid>
                <div style={{padding:'1%'}}></div>
                <Grid item xs={12} md={5.5} xl={5.5} lg={5.5} style={{}}>
                    <Box sx={{
                        textAlign: 'center', fontWeight: 'bold',color:'#00417C',
                        backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "18px", md: "15px", xs: "13px"  }
                    }}>
                        {props.t('DevOps')}
                    </Box>
                    <div style={{ padding: '1%' }}></div>
                    <Box sx={{
                        backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "18px", md: "15px", xs: "13px" },
                        padding: '2%'
                    }}>
                        <img src={DO} style={{ width: '40%', float: 'left', padding: '0% 3%' }} />
                        <div style={{ padding: '2.5%' }}></div>
                        {props.t('For')}
                    </Box>
                </Grid>
                <Grid item xs={0.5} md={0.5} xl={0.5} lg={0.5} style={{}} />
            </Grid>
            <div style={{ padding: '1%' }}></div>
            <Grid container>
                <Grid item xs={12} md={12} xl={12} lg={12} style={{ textAlign: 'center' }}>
                    <Button onClick={Seemore}
                        style={{ textTransform: 'none', fontSize: '15px', backgroundColor: '#BBBBBB', color: '#000000', }}
                    >
                        {props.t('See')}
                    </Button>
                </Grid>
            </Grid>
            <div style={{ padding: '1%' }}></div>
            <Grid container>
                <Grid item xs={12} md={0.5} xl={0.5} lg={0.5} style={{}} />
                <Grid item xs={12} md={6} xl={6} lg={6} style={{ textAlign: 'center' }}>
                    <div style={{ padding: '4%' }}></div>
                    < Slider
                        autoplay={true}
                        arrows={false}
                        autoplaySpeed={7000}
                        duration={500}
                        shift={0}
                    >
                        <div>
                            <img src='../J1.png' alt='' style={{ width: '80%', verticalAlign: 'middle', padding: '1%' }} />
                        </div>
                        <div>
                            <img src="../J2.png" alt='' style={{ width: '80%', verticalAlign: 'middle', padding: '1%' }} />
                        </div>
                        <div>
                            <img src="../J3.png" alt='' style={{ width: '80%', verticalAlign: 'middle', padding: '1%' }} />
                        </div>
                        <div>
                            <img src="../J4.png" alt='' style={{ width: '80%', verticalAlign: 'middle', padding: '1%' }} />
                        </div>
                        <div>
                            <img src="../J5.png" alt='' style={{ width: '80%', verticalAlign: 'middle', padding: '1%' }} />
                        </div>

                    </Slider>
                </Grid>
                <Grid item xs={12} md={5.5} xl={5.5} lg={5.5} style={{}}>
                    <div style={{ padding: '1%' }}></div>
                    <div style={{ textAlign: 'center' }}>
                        <img src={Phone} alt='' style={{ width: '70%' }} />
                    </div>
                </Grid>
            </Grid>
            <div style={{ padding: '1%' }}></div>
            <Grid container>
                <Grid item xs={12} md={1} xl={1} lg={1} style={{}} />
                <Grid item xs={12} md={5.5} xl={5.5} lg={5.5} style={{ textAlign: 'center' }}>
                    <div style={{ padding: '4%' }}></div>
                    <Box sx={{
                        fontSize: '18px', textAlign: 'start', padding: '3.5%',
                        backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "18px", md: "15px", xs: "13px" }
                    }}>
                        &nbsp;&nbsp;&nbsp; {props.t('What')} <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props.t('Restaurant')}<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props.t('Hotell')} <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props.t('School')}<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props.t('Factory')}<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props.t('Shops')}<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props.t('SMEs')}<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props.t('Etccc')}<br />
                        &nbsp;&nbsp;&nbsp;{props.t('You')}
                    </Box>
                </Grid>

                <Grid item xs={12} md={5.5} xl={5.5} lg={5.5} style={{}}>
                    <div style={{ padding: '1.5%' }}></div>
                    <div style={{ textAlign: 'center' }}>
                        <img src={Web} alt='' style={{ width: '70%' }} />
                    </div>
                </Grid>
            </Grid>
            <div style={{ padding: '1.5%' }}></div>
            <Grid container>
                <Grid item xs={12} md={12} xl={12} lg={12} sx={{
                    textAlign: 'center', fontWeight: 'bold',color:'#00417C',
                    backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "18px", md: "15px", xs: "15px"  }
                }}>
                    {props.t('Dev')}
                </Grid>
            </Grid>
            <div style={{ padding: '1%' }}></div>
            <Grid container>
                <Grid item xs={12} md={12} xl={12} lg={12} style={{ textAlign: 'center' }}>
                    <img src={Deops} alt='' style={{ width: '32%' }} />
                </Grid>
            </Grid>
            <div style={{ padding: '1.5%' }}></div>
            <Grid container>
                <Grid item xs={12} md={12} xl={12} lg={12} sx={{
                    textAlign: 'center', fontWeight: 'bold',color:'#00417C',
                    backgroundColor: { xl: "", md: "", xs: "" }, fontSize: { xl: "18px", md: "15px", xs: "15px"  }
                }}>
                    {props.t('Development Stack')}
                </Grid>
            </Grid>
            <div style={{ padding: '1%' }}></div>
            <Grid container>
                <Grid item xs={12} md={12} xl={12} lg={12} style={{ textAlign: 'center' }}>
                    <img src={FB} alt='' style={{ width: '32%' }} />
                </Grid>
            </Grid>
            <div style={{ padding: '2%' }}></div>
        </div >
    )
}

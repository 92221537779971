import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {IconButton} from '@material-ui/core';
import Popover from 'material-ui-popup-state/HoverPopover'
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, FacebookMessengerShareButton, FacebookMessengerIcon, WhatsappShareButton, WhatsappIcon, LineShareButton, LineIcon, EmailShareButton, EmailIcon } from "react-share";
import {Share} from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
        margin: theme.spacing(0),
        },
        cursor: "pointer",
    }
}));

export default function CourseListPopUp(props) {
    const classes = useStyles();

    return (
        <PopupState variant="popover" popupId="demoPopover">
            {popupState => (
                <div >
                    <div className={classes.root} {...bindHover(popupState)} >
                        <IconButton>
                            <Share fontSize='large' style={{color: "#A5A5A5",fontSize:'20px'}} />
                        </IconButton>
                    </div>
                    <Popover {...bindPopover(popupState)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center'}} transformOrigin={{ vertical: 'top', horizontal: 'center'}} disableRestoreFocus>
                        <div style={{ maxWidth: 300, maxHeight: 350, minWidth: 250, backgroundColor: 'white', padding:5, textAlign:"center" }}>
                            <FacebookShareButton url={props.shareLink} ><FacebookIcon size={32} round={true} ></FacebookIcon></FacebookShareButton>&nbsp;&nbsp;
                            <TwitterShareButton url={props.shareLink} ><TwitterIcon size={32} round={true} ></TwitterIcon></TwitterShareButton>&nbsp;&nbsp;
                            <FacebookMessengerShareButton url={props.shareLink} ><FacebookMessengerIcon size={32} round={true} ></FacebookMessengerIcon></FacebookMessengerShareButton>&nbsp;&nbsp;
                            <WhatsappShareButton url={props.shareLink} ><WhatsappIcon size={32} round={true} ></WhatsappIcon></WhatsappShareButton>&nbsp;&nbsp;
                            <LineShareButton url={props.shareLink} ><LineIcon size={32} round={true} ></LineIcon></LineShareButton>&nbsp;&nbsp;
                            <EmailShareButton url={props.shareLink} ><EmailIcon size={32} round={true} ></EmailIcon></EmailShareButton>&nbsp;&nbsp;
                        </div>
                    </Popover>
                </div>
            )}
        </PopupState>
    )
}
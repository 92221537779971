import React from 'react'
import Index from './Apps/index';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './I18n'



const theme = createTheme({
  palette: {
    background:{
      default:'#F6F6F6'
    },
  },
  typography: {
    fontFamily: [
      'Comfortaa',
      'Noto Sans Lao',
    ].join(','),
  },
});



  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<Index/>} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
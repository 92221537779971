import React from 'react'
import Grid from '@mui/material/Grid';
import A2 from '../../modules/A2.png'

export default function Footer(props) {
    return (
        <div>
            <div style={{ backgroundColor: '#4198E9', padding: '0.4%' }} />
            <div style={{ backgroundColor: '#F6F6F6', padding: '0.05%' }} />
            <Grid container>
                <Grid item xs={12} md={1} xl={1} lg={1} style={{ backgroundColor: '#00417C', }}></Grid>
                <Grid item xs={12} md={10} xl={10} lg={10} style={{ backgroundColor: '#00417C', }}>
                    <img src={A2} alt='' style={{ width: '15%', padding: '0.5%' }} />
                </Grid>
                <Grid item xs={1} md={1} xl={1} lg={1} style={{ backgroundColor: '#00417C', }}></Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={12} xl={12} lg={12} sx={{
                    backgroundColor: '#00417C', textAlign: 'center',color:'#ffffff',
                    backgroundColor: { xl: "#00417C", md: "#00417C", xs: "#00417C" }, fontSize: { xl: "22px", md: "15px", xs: "13px" }
                }}>
                    {props.t('Ban')}
                </Grid>
            </Grid>
            <div style={{backgroundColor: '#00417C',padding:'0.5%'}}></div>
            <Grid container>
                <Grid item xs={12} md={12} xl={12} lg={12} sx={{
                    backgroundColor: '#00417C', textAlign: 'center',color:'#ffffff',
                    backgroundColor: { xl: "#00417C", md: "#00417C", xs: "#00417C" }, fontSize: { xl: "22px", md: "15px", xs: "13px" }
                }}>
                    {props.t('copy')} &copy; 2022.
                </Grid>
            </Grid>
            <div style={{backgroundColor: '#00417C',padding:'0.5%'}}></div>
        </div>
    )
}

import React, { useState, useEffect } from 'react'
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import Laos from '../../modules/Lao.png'
import English from '../../modules/English.png'
import ShareIconList from '../ShareIconList'
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { useNavigate, useLocation } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';


const uesStyles = makeStyles((theme) => ({

    menu: {
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
    margin: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    }
}))
export default function Menubar(props) {

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const classes = uesStyles();
    const navigate = useNavigate();
    const Contact = () => {
        setactive('Contact')
        navigate("/contactus")
    };
    const Home = () => {
        setactive('home')
        navigate("/")
    };
    const Services = () => {
        setactive('OurServices')
        navigate("/OurServices")
    }
    const Aboutus = () => {
        setactive('About')
        navigate("/Aboutus")
    }
    const Changelanguagetolao = () => {
        props.i18n.changeLanguage('la')
        localStorage.setItem('lan', 'la')
    }
    const Changelanguagetoenglish = () => {
        props.i18n.changeLanguage('en')
        localStorage.setItem('lan', 'en')
    }
    const [active, setactive] = useState('')
    const location = useLocation()
    useEffect(() => {

        if (location.pathname === '/') {
            setactive('home')
        }
        if (location.pathname === '/OurServices') {
            setactive('OurServices')
        }
        if (location.pathname === '/Aboutus') {
            setactive('About')
        }
        if (location.pathname === '/contactus') {
            setactive('Contact')
        }
    }, [])

    return (
        <div>
            <div style={{ backgroundColor: '#4198E9', padding: '0.2%' }} />
            <div style={{ backgroundColor: '#F6F6F6', padding: '0.03%' }} />
            <div style={{ backgroundColor: '#00417C', }}>
                <Box style={{ display: { xs: 'flex', md: 'none' } }} className={classes.menu} >
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                    >
                        <MenuIcon style={{ color: '#ffffff', fontSize: '110%' }} />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        style={{
                            display: { xs: 'block', md: 'none' },
                        }}>
                        <MenuItem onClick={() => { handleCloseNavMenu(); Home(); }}>
                            <Typography > {props.t('Home')}</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => { handleCloseNavMenu(); Services(); }}>
                            <Typography >  {props.t('Services')}</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => { handleCloseNavMenu(); Aboutus(); }}>
                            <Typography> {props.t('About Us')}</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => { handleCloseNavMenu(); Contact(); }}>
                            <Typography>{props.t('Contact Us')}</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => { handleCloseNavMenu(); Changelanguagetolao(); }}>
                            <img src={Laos} alt="" style={{ width: '35px', margin: 'auto' }} />
                        </MenuItem>
                        <MenuItem onClick={() => { handleCloseNavMenu(); Changelanguagetoenglish(); }}>
                            <img src={English} alt="" style={{ width: '35px', margin: 'auto' }} />
                        </MenuItem>
                        <MenuItem onClick={() => { handleCloseNavMenu(); }} style={{ justifyContent: 'center' }}>
                            <a href='https://www.facebook.com/besttech.la'
                                target={'_blank'} rel="noopener noreferrer"
                                style={{ display: 'flex' }}>
                                <Tooltip title="Like us on facebook" style={{ backgroundColor: '#ffffff' }}>
                                    <FacebookOutlinedIcon style={{ color: '#2534C0', fontSize: '150%' }} />
                                </Tooltip>
                            </a>
                        </MenuItem>
                        <MenuItem onClick={() => { handleCloseNavMenu(); }} style={{ justifyContent: 'center' }}>
                            <ShareIconList style={{ fontSize: '100%', color: '#000000' }} shareLink={"https://www.besttech.la/"} />
                        </MenuItem>
                    </Menu>
                </Box>
                <div style={{ backgroundColor: '', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ flex: 2 }}></div>
                    <div style={{ flex: 0.5, textAlign: 'center' }} >
                        <div
                            style={{
                                color: 'white', padding: '10%', textTransform: 'none', fontSize: '1vw', cursor: 'pointer',
                                backgroundColor: active === 'home' ? '#A4CCF2' : ''
                            }}
                            className={classes.margin}
                            onClick={() => { handleCloseNavMenu(); Home(); }}>
                            {props.t('Home')}
                        </div>
                    </div>
                    <div style={{ flex: 0.7, textAlign: 'center' }} >
                        <div style={{
                            textTransform: 'none', fontSize: '1vw', color: 'white', padding: '7%', cursor: 'pointer',
                            backgroundColor: active === 'OurServices' ? '#A4CCF2' : ''
                        }}
                            className={classes.margin}
                            onClick={() => { handleCloseNavMenu(); Services(); }}>
                            {props.t('Services')}
                        </div>
                    </div>
                    <div style={{ flex: 0.5, textAlign: 'center' }} >
                        <div style={{
                            textTransform: 'none', fontSize: '1vw', color: 'white', padding: '10%', cursor: 'pointer',
                            backgroundColor: active === 'About' ? '#A4CCF2' : ''
                        }}
                            className={classes.margin}
                            onClick={() => { handleCloseNavMenu(); Aboutus(); }}>
                            {props.t('About Us')}
                        </div>
                    </div>
                    <div style={{ flex: 0.5, textAlign: 'center' }} >
                        <div style={{
                            textTransform: 'none', fontSize: '1vw', color: 'white', padding: '10%', cursor: 'pointer',
                            backgroundColor: active === 'Contact' ? '#A4CCF2' : ''
                        }}
                            className={classes.margin}
                            onClick={() => { handleCloseNavMenu(); Contact(); }}>
                            {props.t('Contact Us')}
                        </div>
                    </div>

                    <div style={{ flex: 1.5 }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

                            <div style={{ xs: 'none', md: 'flex', cursor: 'pointer' }}
                                className={classes.margin}
                                onClick={() => { handleCloseNavMenu(); }}>
                                <div style={{ padding: '10%' }}></div>
                                <a href='https://www.facebook.com/besttech.la' 
                                target={'_blank'} rel="noopener noreferrer"
                                style={{}}>
                                    <Tooltip title="Like us on facebook" style={{ backgroundColor: '#ffffff' }}>
                                        <FacebookOutlinedIcon style={{ color: '#ffffff', fontSize: '19px' }} />
                                    </Tooltip>
                                </a>
                            </div>

                            <div style={{ xs: 'none', md: 'flex', cursor: 'pointer' }}
                                className={classes.margin}
                                onClick={() => { handleCloseNavMenu(); }}>
                                <ShareIconList style={{ padding: '' }} shareLink={"https://www.besttech.la/"} />
                            </div>
                        </div>
                    </div>
                    <div style={{ flex: 0.5 }} >
                        <div style={{ padding: '3%' }}></div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ xs: 'none', md: 'flex', cursor: 'pointer' }}
                                className={classes.margin}
                                onClick={() => { Changelanguagetolao(); handleCloseNavMenu(); }}>
                                <img src={Laos} alt="" style={{ width: '35px', margin: 'auto' }} />
                            </div>
                            <div style={{ padding: '3%' }}></div>
                            <div style={{ xs: 'none', md: 'flex', cursor: 'pointer' }}
                                className={classes.margin}
                                onClick={() => { Changelanguagetoenglish(); handleCloseNavMenu(); }}>
                                <img src={English} alt="" style={{ width: '35px', margin: 'auto' }} />
                            </div>
                        </div>
                    </div>
                    <div style={{ flex: 0.05 }}></div>
                </div>
            </div>
        </div>
    )
}

import React from 'react'
import { Route, Routes, Navigate } from "react-router-dom";
import Homepage from './Home/Homepage';
import OurServiec from './Services/OurServiec';
import Aboutus from './Aboutus/Aboutus';
import Contactus from './Contactus/Contactus';
import Appbar from './Appbar/Appbar'
import Menubar from './Appbar/Menubar';
import Slide from './Appbar/Slide'
import Footer from './Footer/Footer'
import { useTranslation } from 'react-i18next';


export default function Index() {
  const { t, i18n } = useTranslation()
  React.useEffect(() => {
    var lan = localStorage.getItem('lan')
    if (lan) {
      console.log(lan)
      if (lan === 'la') {
        i18n.changeLanguage('la')
      }
      if (lan === 'en') {
        i18n.changeLanguage('en')
      }
    }
    else {
      i18n.changeLanguage('la')
    }
  }, [i18n])

  return (
    <div >
      <Appbar />
      <Menubar  t={t} i18n={i18n}/>
      <Slide />
      <Routes>
        <Route index path="*" element={<Navigate to="/" replace />} />
        <Route path="/" element={<Homepage  t={t} i18n={i18n}/>} />
        <Route path="/OurServices" element={<OurServiec  t={t} i18n={i18n}/>} />
        <Route path="/Aboutus" element={<Aboutus  t={t} i18n={i18n}/>} />
        <Route path="/Contactus" element={<Contactus  t={t} i18n={i18n}/>} />
      </Routes>
      <Footer  t={t} i18n={i18n}/>
    </div>
  )
}
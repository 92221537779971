import React from 'react'
import Slider from 'infinite-react-carousel';
// import W1 from '../../public/1.png'

export default function Carousel() {
  return (
    < Slider
      autoplay={true}
      arrows={false}
      autoplaySpeed={7000}
      duration={500}
      shift={0}
    >
      <div>
        <img src='../W1.jpg' alt='' style={{ width: '100%',verticalAlign: 'middle'}} />
      </div>
      <div>
        <img src="../W3.jpg" alt='' style={{ width: '100%',verticalAlign: 'middle'}} />
      </div>
      <div>
        <img src="../W2.jpg" alt='' style={{ width: '100%',verticalAlign: 'middle'}} />
      </div>
      
    </Slider>
  );
}